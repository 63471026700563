import React, {Component} from "react";
import {Container, Message} from "semantic-ui-react";
import {
    browserName,
    browserVersion,
    deviceDetect,
    deviceType,
    engineName,
    engineVersion,
    fullBrowserVersion,
    getUA,
    isAndroid,
    isBrowser,
    isChrome,
    isChromium,
    isConsole,
    isEdge,
    isElectron,
    isFirefox,
    isIE,
    isIOS,
    isIOS13,
    isIPad13,
    isIPhone13,
    isIPod13,
    isMobile,
    isMobileOnly,
    isMobileSafari,
    isOpera,
    isSafari,
    isSmartTV,
    isTablet,
    isWearable,
    isWinPhone,
    isYandex,
    mobileModel,
    mobileVendor,
    osName,
    osVersion
} from "react-device-detect";

export default class DeviceDetector extends Component {
    constructor(props, context) {
        super(props, context);
        console.log(`isMobile: ${isMobile}`);
        console.log(`isMobileOnly: ${isMobileOnly}`);
        console.log(`isTablet: ${isTablet}`);
        console.log(`isBrowser: ${isBrowser}`);
        console.log(`isSmartTV: ${isSmartTV}`);
        console.log(`isWearable: ${isWearable}`);
        console.log(`isConsole: ${isConsole}`);
        console.log(`isAndroid: ${isAndroid}`);
        console.log(`isWinPhone: ${isWinPhone}`);
        console.log(`isIOS: ${isIOS}`);
        console.log(`isChrome: ${isChrome}`);
        console.log(`isFirefox: ${isFirefox}`);
        console.log(`isSafari: ${isSafari}`);
        console.log(`isOpera: ${isOpera}`);
        console.log(`isIE: ${isIE}`);
        console.log(`isEdge: ${isEdge}`);
        console.log(`isYandex: ${isYandex}`);
        console.log(`isChromium: ${isChromium}`);
        console.log(`isMobileSafari: ${isMobileSafari}`);
        console.log(`osVersion: ${osVersion}`);
        console.log(`osName: ${osName}`);
        console.log(`fullBrowserVersion: ${fullBrowserVersion}`);
        console.log(`browserVersion: ${browserVersion}`);
        console.log(`browserName: ${browserName}`);
        console.log(`mobileVendor: ${mobileVendor}`);
        console.log(`mobileModel: ${mobileModel}`);
        console.log(`engineName: ${engineName}`);
        console.log(`engineVersion: ${engineVersion}`);
        console.log(`getUA: ${getUA}`);
        console.log(`deviceType: ${deviceType}`);
        console.log(`isIOS13: ${isIOS13}`);
        console.log(`isIPhone13: ${isIPhone13}`);
        console.log(`isIPad13: ${isIPad13}`);
        console.log(`isIPod13: ${isIPod13}`);
        console.log(`isElectron: ${isElectron}`);
        console.log(JSON.stringify(deviceDetect()));
    }

    render() {
        return (
            <Container>
                <Message.Header>isMobile</Message.Header>
                <p>
                    {isMobile.toString()}
                </p>

                <Message.Header>isMobileOnly</Message.Header>
                <p>
                    {isMobileOnly.toString()}
                </p>

                <Message.Header>isTablet</Message.Header>
                <p>
                    {isTablet.toString()}
                </p>

                <Message.Header>isBrowser</Message.Header>
                <p>
                    {isBrowser.toString()}
                </p>

                <Message.Header>isSmartTV</Message.Header>
                <p>
                    {isSmartTV.toString()}
                </p>

                <Message.Header>isWearable</Message.Header>
                <p>
                    {isWearable.toString()}
                </p>

                <Message.Header>isConsole</Message.Header>
                <p>
                    {isConsole.toString()}
                </p>

                <Message.Header>isAndroid</Message.Header>
                <p>
                    {isAndroid.toString()}
                </p>

                <Message.Header>isWinPhone</Message.Header>
                <p>
                    {isWinPhone.toString()}
                </p>

                <Message.Header>isIOS</Message.Header>
                <p>
                    {isIOS.toString()}
                </p>

                <Message.Header>isChrome</Message.Header>
                <p>
                    {isChrome.toString()}
                </p>

                <Message.Header>isFirefox</Message.Header>
                <p>
                    {isFirefox.toString()}
                </p>

                <Message.Header>isSafari</Message.Header>
                <p>
                    {isSafari.toString()}
                </p>

                <Message.Header>isOpera</Message.Header>
                <p>
                    {isOpera.toString()}
                </p>

                <Message.Header>isIE</Message.Header>
                <p>
                    {isIE.toString()}
                </p>

                <Message.Header>isEdge</Message.Header>
                <p>
                    {isEdge.toString()}
                </p>

                <Message.Header>isYandex</Message.Header>
                <p>
                    {isYandex.toString()}
                </p>

                <Message.Header>isChromium</Message.Header>
                <p>
                    {isChromium.toString()}
                </p>

                <Message.Header>isMobileSafari</Message.Header>
                <p>
                    {isMobileSafari.toString()}
                </p>

                <Message.Header>osVersion</Message.Header>
                <p>
                    {osVersion.toString()}
                </p>

                <Message.Header>osName</Message.Header>
                <p>
                    {osName.toString()}
                </p>

                <Message.Header>fullBrowserVersion</Message.Header>
                <p>
                    {fullBrowserVersion.toString()}
                </p>

                <Message.Header>browserVersion</Message.Header>
                <p>
                    {browserVersion.toString()}
                </p>

                <Message.Header>browserName</Message.Header>
                <p>
                    {browserName.toString()}
                </p>

                <Message.Header>mobileVendor</Message.Header>
                <p>
                    {mobileVendor.toString()}
                </p>

                <Message.Header>mobileModel</Message.Header>
                <p>
                    {mobileModel.toString()}
                </p>

                <Message.Header>engineName</Message.Header>
                <p>
                    {engineName.toString()}
                </p>

                <Message.Header>engineVersion</Message.Header>
                <p>
                    {engineVersion.toString()}
                </p>

                <Message.Header>getUA</Message.Header>
                <p>
                    {getUA.toString()}
                </p>

                <Message.Header>deviceType</Message.Header>
                <p>
                    {deviceType.toString()}
                </p>

                <Message.Header>isIOS13</Message.Header>
                <p>
                    {isIOS13.toString()}
                </p>

                <Message.Header>isIPhone13</Message.Header>
                <p>
                    {isIPhone13.toString()}
                </p>

                <Message.Header>isIPad13</Message.Header>
                <p>
                    {isIPad13.toString()}
                </p>

                <Message.Header>isIPod13</Message.Header>
                <p>
                    {isIPod13.toString()}
                </p>

                <Message.Header>isElectron</Message.Header>
                <p>
                    {isElectron.toString()}
                </p>
            </Container>
        );
    }
}